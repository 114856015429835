"use client";
import { NavItem } from "@/app/types";
import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useState } from "react";

type HeaderProps = {
	navItem: NavItem[];
};

const Header = ({ navItem }: HeaderProps) => {
	const [isOpen, setOpen] = useState<boolean>(false);
	const handleMenuOpen = () => setOpen(!isOpen);
	const handleMenuClose = () => setOpen(false);
	const pathname = usePathname();

	return (
		<header className="xl:p-4 shadow fixed top-0 w-screen z-20 bg-white">
			<div className="relative p-4 xl:p-0 flex justify-between max-w-7xl mx-auto">
				<h1 className="text-xl md:text-2xl xl:text-4xl font-bold serif">
					<Link href="/" className="flex gap-2 items-center xl:col-span-4">
						<Image
							src={"/logo.png"}
							alt={"河村建装のロゴマーク"}
							width={72}
							height={63}
						/>
						<span className="text-sm md:text-lg xl:text-xl">株式会社</span>
						河村建装
					</Link>
				</h1>
				<div
					className={clsx(
						"absolute w-screen top-[95px] left-0 overflow-hidden xl:p-0 xl:w-auto xl:h-auto xl:flex xl:static xl:gap-x-8 duration-300 z-10 bg-white",
						isOpen
							? "h-[424px] pb-6 shadow xl:shadow-none"
							: "h-0 col-span-8 grid-cols-8 gap-x-8",
					)}
				>
					<nav
						className={clsx(
							"xl:col-span-5",
							isOpen
								? "grid col-span-1 items-center font-bold"
								: "grid col-span-5 items-center font-bold",
						)}
					>
						<ul className="xl:flex justify-end gap-8 text-center">
							{navItem.map((item) => (
								<li key={item.title}>
									<Link
										href={item.slug === "home" ? "/" : `/${item.slug}`}
										className={
											pathname === `/${item.slug}` ||
											(pathname === "/" && item.slug === "home")
												? "block p-4 xl:p-0 text-primary"
												: "block p-4 xl:p-0"
										}
										onClick={handleMenuClose}
									>
										{item.title}
									</Link>
								</li>
							))}
						</ul>
					</nav>

					<Link
						href="tel:072-257-3565"
						className="grid py-2 px-6 max-w-xs mx-auto gap-x-2 bg-primary grid-cols-[auto_1fr] items-center justify-center text-white col-span-3 text-center hover:opacity-80 duration-300"
					>
						<Image
							src="/tel-w.svg"
							alt="電話のアイコン"
							width={24}
							height={24}
							className="row-span-2"
						/>
						<p className="font-bold text-2xl tracking-wider">0721-26-8093</p>
						<p className="text-xs tracking-wider">
							お気軽にお問い合わせください
						</p>
					</Link>
				</div>
				<button
					className="grid items-center z-20 xl:hidden"
					onClick={handleMenuOpen}
					type="button"
				>
					<div
						className={clsx(
							'relative w-6 h-1 duration-300 rounded-full before:content-[""] before:absolute before:w-6 before:h-1 before:rounded-full before:bg-primary before:left-0 before:duration-300 after:content-[""] after:absolute after:w-6 after:h-1 after:rounded-full after:bg-primary after:left-0 after:duration-300',
							isOpen
								? "bg-transparent before:rotate-45 after:-rotate-45"
								: "bg-primary before:-translate-y-2 after:translate-y-2",
						)}
					/>
				</button>
			</div>
		</header>
	);
};

export default Header;
