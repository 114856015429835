"use client";

import clsx from "clsx";
import { useInView } from "react-intersection-observer";

const FadeIn = ({ children }: { children: React.ReactNode }) => {
	const { ref, inView } = useInView({
		rootMargin: "-100px",
		triggerOnce: true,
	});

	return (
		<span
			className={clsx(
				"duration-500",
				inView ? "" : "opacity-0 translate-y-[50px]",
			)}
			ref={ref}
		>
			{children}
		</span>
	);
};

export default FadeIn;
